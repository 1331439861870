<template lang="pug">
  .calendar-month
    .calendar-month-header
      .calendar-month-pagination
        .first-month
          span {{ "<<" }}
        .prev-month(@click="setPrevMonth")
          span {{ "<" }}
        .current-month
          span {{ currentMonthText }}
        .next-month(@click="setNextMonth")
          span {{ ">" }}
        .last-month
          span {{ ">>" }}
    .days-of-week
      .day(v-for="day in daysOfWeek")
        span {{ $t(`plan_management.modal.jalan_sale_settings.days_of_weeks.${day}`) }}
    .days-of-month
      template(v-for="day in daysRange")
        .day-cell(:class="buildDayClassObject(day)")
          span {{ getMonthDay(day) }}
          .plan-day(v-if="isIncludedInPeriod(day)")
          .special-day(
            v-if="isIncludedInSpecialPeriodRange(day)"
            :class="{'first': isFirstSpecialPeriodDay(day), 'last': isLastSpecialPeriodDay(day) }"
          )
            .lane(:class="[specialPeriodColor, buildLaneClassObject(day)]")
              .name(
                v-if="isFirstSpecialPeriodDay(day)"
              )
                span {{ specialPeriodName }}
</template>

<script>
  const DAYS_OF_WEEK = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]

  export default {
    props: {
      period: {
        type: Array,
        default: () => new Array()
      },
      specialPeriodName: {
        type: String,
        default: ""
      },
      specialPeriod: {
        type: Array,
        default: () => new Array()
      },
      specialPeriodColor: {
        type: String,
        default: "red"
      }
    },

    data() {
      return {
        currentDate: new Date(),
        daysOfWeek: DAYS_OF_WEEK
      }
    },

    methods: {
      buildLaneClassObject(day) {
        return {
          sunday: this.isSunday(day),
          saturday: this.isSaturday(day),
          first: this.isFirstSpecialPeriodDay(day),
          last: this.isLastSpecialPeriodDay(day)
        }
      },

      buildDayClassObject(date) {
        return {
          "not-current-month": this.isNotCurrentMonth(date),
          sunday: this.isSunday(date),
          saturday: this.isSaturday(date)
        }
      },

      isIncludedInPeriod(date) {
        return this.period[0] <= date && date <= this.period[1]
      },

      isIncludedInSpecialPeriodRange(date) {
        return this.specialPeriod[0] <= date && date <= this.specialPeriod[1]
      },

      isNotCurrentMonth(date) {
        return date.getMonth() !== this.currentMonth
      },

      isSunday(date) {
        return date.getDay() === 0
      },

      isSaturday(date) {
        return date.getDay() === 6
      },

      isFirstSpecialPeriodDay(date) {
        return this.specialPeriod[0]?.getTime() === date.getTime()
      },

      isLastSpecialPeriodDay(date) {
        return this.specialPeriod[1]?.getTime() === date.getTime()
      },

      setPrevMonth() {
        this.currentDate = new Date(this.currentYear, this.currentMonth - 1)
      },

      setNextMonth() {
        this.currentDate = new Date(this.currentYear, this.currentMonth + 1)
      },

      getMonthDay(date) {
        return date.toLocaleDateString(undefined, { day: "numeric" })
      }
    },

    computed: {
      currentYear() {
        return this.currentDate.getFullYear()
      },

      currentMonth() {
        return this.currentDate.getMonth()
      },

      currentMonthText() {
        return `${this.currentYear} ${this.currentDate.toLocaleDateString(undefined, { month: "2-digit" })}`
      },

      currentMonthStartDate({ currentYear, currentMonth }) {
        const firstDayOfMonth = new Date(currentYear, currentMonth, 1)
        const weekStartDate = new Date(firstDayOfMonth)
        weekStartDate.setDate(firstDayOfMonth.getDate() - firstDayOfMonth.getDay())
        return weekStartDate
      },

      currentMonthLastDate({ currentYear, currentMonth }) {
        const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0)
        const weekEndDate = new Date(lastDayOfMonth)
        weekEndDate.setDate(lastDayOfMonth.getDate() + (6 - lastDayOfMonth.getDay()))
        return weekEndDate
      },

      daysRange({ currentMonthStartDate, currentMonthLastDate }) {
        const daysRange = []
        let rangeDay = new Date(currentMonthStartDate)
        while (rangeDay <= currentMonthLastDate) {
          daysRange.push(new Date(rangeDay))
          rangeDay.setDate(rangeDay.getDate() + 1)
        }

        return daysRange
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .calendar-month
    .calendar-month-header
      margin: 10px 200px
      .calendar-month-pagination
        display: flex
        justify-content: space-between
        color: $default-gray

        .prev-month,
        .next-month
          cursor: pointer

        .current-month
          width: 200px
          font-weight: bold
          text-align: center

    .days-of-week
      background-color: $default-gray-light
      font-weight: bold
      color: $default-gray
      display: grid
      grid-template-columns: repeat(7, 1fr)

      .day
        text-align: center

    .days-of-month
      grid-gap: 1px
      background-color: $default-gray-light
      display: grid
      grid-template-columns: repeat(7, 1fr)
      border: 1px solid $default-gray-light

      .day-cell
        background-color: $default-white
        color: $default-gray
        padding-top: 5px
        text-align: center
        height: 98px

        .special-day,
        .plan-day
          height: 20px
          margin-top: 5px
          margin-right: -1px

        .plan-day
          background-color: $default-orange

        .special-day
          border-top: 1px solid $default-black
          border-bottom: 1px solid $default-black
          background-color: $default-white

          &.first
            border-left: 1px solid $default-black
          &.last
            border-right: 1px solid $default-black

          .lane
            margin: 1px -1px
            height: 16px

            .name
              padding-left: 3px
              color: white
              font-size: 0.7rem
              width: 80px
              text-align: left
              /*white-space: nowrap*/
              /*overflow: hidden*/
              /*text-overflow: ellipsis*/

              span


            &.first
              position: relative
              margin-left: 1px !important
            &.last
              margin-right: 1px !important
            &.sunday
              margin-left: 0px
            &.saturday
              margin-right: 0px

            &.red
              background-color: $default-red
            &.blue
              background-color: $default-blue-dark
            &.yellow
              background-color: $default-yellow-light
            &.pink
              background-color: $default-red-medium
            &.green
              background-color: $default-green

        &.not-current-month
          span
            opacity: 0.5
        &.sunday
          color: $default-red
        &.saturday
          color: $default-blue-dark
</style>
